const REGEXP = {
  email: /^[^\s+]+@\S+\.\S+$/,
  // name: /^\p{Letter}[\p{Letter} '-]{0,159}$/u
  name: /^[A-Za-z][A-Za-z ’'`-]{0,159}$/,
  password: /^(?=(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*(?=\p{P}|\p{S}))|(?=.*[a-z])(?=.*(?=\p{P}|\p{S}))(?=.*[0-9])|(?=.*[A-Z])(?=.*(?=\p{P}|\p{S}))(?=.*[0-9]))[a-zA-Z0-9(?=\p{P}|\p{S})]{8,160}$/u,
  ipAddress: /^((([1-9]|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])){3})|(0\.0\.0\.0))(\/(3[0-2]|[1-2]\d|\d))?$/,
  // eslint-disable-next-line no-useless-escape
  passPhrase: /^(?=(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])|(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])|(?=.*[a-z])(?=.*[0-9])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[A-Z])|(?=.*[a-z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[0-9])(?=.*[A-Z])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[0-9])|(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])|(?=.*[A-Z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[a-z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[0-9])(?=.*[a-z])|(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[a-z])|(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])|(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])|(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])|(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])|(?=.*[0-9])(?=.*[A-Z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[a-z])|(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])|(?=.*[0-9])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[a-z])(?=.*[A-Z])|(?=.*[0-9])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[A-Z])(?=.*[a-z])|(?=.*[0-9])(?=.*[a-z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;])(?=.*[A-Z])|(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%^\-=&\*()@\[\]\.,:;]))[a-zA-Z0-9!#$%^\-=&\*()@\[\]\.,:;]{20,160}$/,
  passwordLength: /^.{8,160}$/,
  passwordComplexity: /^(?=(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*(?=\p{P}|\p{S}))|(?=.*[a-z])(?=.*(?=\p{P}|\p{S}))(?=.*[0-9])|(?=.*[A-Z])(?=.*(?=\p{P}|\p{S}))(?=.*[0-9]))[a-zA-Z0-9(?=\p{P}|\p{S})]{1,}$/u
};

export default REGEXP;
